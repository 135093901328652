import dynamic from "next/dynamic";

const GeneralHeader = dynamic(import("../Header/GeneralHeader"));
const GeneralFooter = dynamic(import("../Footer/GeneralFooter"));

export default function DefaultLayout(props) {
	return (
		<div className="layout">
			<GeneralHeader nav={props.nav} logo={props.logo} />
			{props.children}
			{props.footer === false ? null : (
				<GeneralFooter footer={props.footer} />
			)}
		</div>
	);
}